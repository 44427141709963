<template >
  <div  id="premiumPlans">
     <MembershipHeader :premium = "true" />
  <div v-if="$store.getters.getShowError == false" :style="[$store.getters.getShowLoader ? {'display': 'block'} : {'display': 'block'}]">
    <div v-if="showPlans">
      
      <!-- Welcome Modal -->
        <div v-if="!isMobile && false">
        <b-modal id="premiumFeatureModal" v-model="featureModalShow" size="lg" hide-footer hide-header>
            <div class="exit-button" @click="featureModalShow = false">
              <img src="../../../assets/images/Close-cross-Grey.svg" alt="Exit icon" />
            </div>
            <div class="container">
                <div class="row">
                <div class="image col-4">
                </div>
                <div class="content col-lg-8 col-12">
                <h1>
                  {{ $t($i18n.locale + '.keys.premiumFeatureModal.headline',{productName: $store.getters.productName,}) }}
                </h1>
                <p
                class="infoText1"
                v-html="$t($i18n.locale + '.keys.premiumFeatureModal.infoText1',{productName: $store.getters.productName,})"
                >

                </p>
                <p class="infoText2">
                  {{ $t($i18n.locale + '.keys.premiumFeatureModal.infoText2') }}
                </p>
                <div class="bottom">
                  <b-button variant="primary" class="my-3" @click="featureModalShow = false">
                    {{ $t($i18n.locale + '.keys.premiumFeatureModal.button') }}
                  </b-button>
                </div>
                </div>
              </div>
            </div>
          </b-modal>
      </div>
      <div v-else-if="isMobile">
          <div class="errorMessage" v-if="featureModalShow">
              <p>{{$t($i18n.locale + ".keys.premiumPlans.featureForPremiums") }}</p>
          </div>
      </div>
    <!-- / Welcome Modal -->

    <!-- Mobile Back Button -->
      <router-link to="/membership-area/home" id="skipBtn" v-if="isMobile && $route.query.signup == 1">
        {{ $t($i18n.locale).keys.generic.skipBtn }} &gt;
      </router-link>
      <a href="" id="backBtn"
        :class="{
          featureShow: featureModalShow,
        }"
      @click.prevent="goBack"
      v-else-if="isMobile">
        <img  src="../../../assets/images/Arrow-white-left.svg" />
        <span>
          &lt;
        </span>
      </a>
    <!-- / Mobile Back Buttton -->

    <div  class="payment_plans_wrapper container-lg" :class="{ variant2: desktopPlansVariant == 2 }">

      <div
        id="plansBackground_variant2"
        :style="{'background-image': 'url(' + require('@/assets/images/new_payment_bg.jpg') +')'}"
        v-if="desktopPlansVariant == 2 && tabIndex == 0 && !isMobile"
      ></div>

      <!-- Desktop Payment Tabs -->
      <b-tabs
        id="premiumPlansTabsDesktop"
        :class="'tab_' + (tabIndex+1)"
        v-model="tabIndex"
        v-if="!isMobile"
        key="desktop-payments"
      >
        <!-- Payment Plans -->
        <b-tab active key="payment-plans-desktop">
              <template v-slot:title>
                <div class="tab-wrapper" @click="($route.query.signup == 1) ? $router.push('/premium-plans?signup=1'):$router.push('/premium-plans')">
                  <p class="title" >
                    {{ $t($i18n.locale + ".keys.premiumPlans.plansTabs.chooseMembership") }}
                  </p>

                  <div class="container">
                    <div class="circle1"></div>
                  </div>
                </div>
              </template>

          <PlansChoices 
              @nextTab="nextTab"
              :desktop-plans-variant="desktopPlansVariant"
              :mobile-plans-variant="mobilePlansVariant"
          />

        </b-tab>
        <!-- /Payment Plans -->
        <!-- Payment Forms -->
        <b-tab key="process-payment-desktop">
          <template v-slot:title>
            <div class="tab-wrapper"
              @click.prevent="return false;"
            >
              <p class="title">
                {{ $t($i18n.locale + ".keys.premiumPlans.plansInfo.purchaseMembership") }}
              </p>
              <!-- change sth v-model below to translation key -->
              <div class="container">
                <div class="circle2"></div>
              </div>
            </div>
          </template>

          <ProcessPaymentsVar2 ref="paymentForm"
            v-if="paymentVariant == 2" 
            @selectMethod="selectMethod"  
            :selectedMethod="selectedMethod" 
            :processor="CreditCardProcessor"
            :enableSEPA="enableSEPA"
          />

          <ProcessPayments ref="paymentForm"
            v-else 
            @selectMethod="selectMethod"  
            :selectedMethod="selectedMethod" 
            :processor="CreditCardProcessor"
            :enableSEPA="enableSEPA"
          />
        </b-tab>
        <!-- /Payment Forms -->

        <!-- BACK Buttons -->
        <b-tab
          :title="$t($i18n.locale).keys.generic.skipBtn +' &gt;'"
          title-item-class="skipBtnTab"
          v-if="$route.query.signup == 1"
          @click="$router.push('/membership-area/home')"
          key="skip-btn"
        ></b-tab>
        <b-tab
          :title="'&lt; ' + $t($i18n.locale).keys.generic.backBtn"
          title-item-class="skipBtnTab"
          v-else
          @click.prevent="goBack"
          key="back-btn"
        ></b-tab>
        <!-- /BACK Buttons -->
      
      </b-tabs>
      <!-- /Desktop Payment Tabs -->
      
      <!-- Mobile Payment Tabs -->
      <b-tabs
        id="premiumPlansTabs"
        v-model="tabIndex"
        v-if="isMobile"
        class="wizard"
        key="mobile-payment"
      >
        <!-- Payment Plans -->
        <b-tab active title-item-class="disabledTab" key="payment-plans-mobile">
            <template v-slot:title>
              <div class="tab-wrapper" @click.stop.prevent="">
                <div class="container">
                  <div class="tab-circle">1</div>
                </div>
              </div>
            </template>

            <PlansChoices 
              @nextTab="nextTab"
              :desktop-plans-variant="desktopPlansVariant"
              :mobile-plans-variant="mobilePlansVariant"
            />

        </b-tab>
         <!-- /Payment Plans -->
        <!-- Payment Methods -->
        <b-tab title-item-class="disabledTab" v-if="!skipPaymentOptions" key="payment-options">
          <template v-slot:title>
            <div class="tab-wrapper" @click.stop.prevent="">

              <div class="container">
                <div class="tab-circle">2</div>
              </div>
            </div>
          </template>
          <div id="paymentMethodTab">
            <h3>
              {{ $t($i18n.locale + '.keys.premiumPlans.processPayments.choosePreferredMethod') }}
            </h3>
            <div
            class="paymentMethodSelect"
            @click="selectMethod('CARD')"
            >
            <div class="title">{{ $t($i18n.locale + '.keys.premiumPlans.processPayments.selectedMethodCard') }}</div>
            <div class="images">
              <img src="../../../assets/images/MasterCard2.png" alt="Master card">
              <img src="../../../assets/images/Visa2.png" alt="VISA">
            </div>
            </div>

            <div class="sslBadge">
              <img
                class="sslBadge"
                src="../../../assets/images/positive-ssl.png"
                alt="SSL badge"
              />
            </div>
            <div class="additionalInfo">
              <p v-html="$t($i18n.locale + '.keys.premiumPlans.processPayments.additionalInfo', {
                backLink: ($route.query.signup == 1) ? '/premium-plans/?signup=1':'/premium-plans/'
              })"></p>
            </div>
          </div>
        </b-tab>
        <!-- /Payment Methods -->
        <!-- Payment Forms -->
        <b-tab title-item-class="disabledTab lockedTab" key="process-payment-mobile">
          <template v-slot:title>
            <div class="tab-wrapper" @click.stop.prevent="">
              <div class="container">
                <div class="tab-circle" v-if="skipPaymentOptions">2</div>
                <div class="tab-circle" v-else>3</div>
              </div>
            </div>
          </template>

          <ProcessPaymentsVar2 ref="paymentForm"
            v-if="paymentVariant == 2" 
            :selectedMethod="selectedMethod"
            @showSummary="showSummary"
            @selectMethod="selectMethod"
            :processor="CreditCardProcessor"
            :enableSEPA="enableSEPA"
          />

          <ProcessPayments ref="paymentForm"
            v-else
            :selectedMethod="selectedMethod"
            @showSummary="showSummary"
            @selectMethod="selectMethod"
            :processor="CreditCardProcessor"
            :enableSEPA="enableSEPA"
          />
        </b-tab>
        <!-- /Payment Forms -->
        <!-- Payment Summary -->
        <b-tab title-item-class="disabledTab" v-if="paymentVariant != 3" key="mobile-summary">
          <template v-slot:title>
            <div class="tab-wrapper"  @click.stop.prevent="">

              <div class="container">
                <div class="tab-circle" v-if="skipPaymentOptions">3</div>
                <div class="tab-circle" v-else>4</div>
              </div>
            </div>
          </template>
          <PaymentSummaryTab 
            v-if="showSummaryTab"
            :payment-variant = "paymentVariant"
            :checkout-id = "checkoutId"
            :sec-form = "secForm"
            :processor = "CreditCardProcessor"
            :sepa_form_data="sepa_data"
            :magnius-data-summary="magniusDataSummary"
            :celeris-data-summary="celerisDataSummary"
            :selected-method="selectedMethod"
            :securion-primary-key="secData.securionPrimaryKey"
            :securion-fallback-key="secData.securionFallbackKey"
            :count-payment-attempt="secData.countPaymentAttempt"

          />
        </b-tab>
        <!-- /Payment Summary -->
      </b-tabs>
      <!-- /Mobile Payment Tabs -->

    </div>
      
      
      <!--
      <ServicesAdvantages />
      <PlansBenefits />
      <PaymentMethods />
      -->
    </div>

  </div>
  <!-- ERROR HANDLING -->
  <div v-else-if="$store.getters.getShowError">
    
  </div>
  
  <!-- <MembershipFooter :payment="true" /> -->
  <!-- <iframe id="paymentIFrame" name="ppFrame" width="100%" height="100%" ></iframe> -->
  </div>
</template>

<script>

import MembershipHeader from "@/components/membership-area/MembershipHeader.vue";
//import MembershipFooter from "@/components/membership-area/MembershipFooter.vue";
import PlansChoices from "@/components/membership-area/premium-plans/PlansChoices.vue";
import ProcessPayments from "@/components/membership-area/premium-plans/processVariants/ProcessPayments.vue";
import ProcessPaymentsVar2 from "@/components/membership-area/premium-plans/processVariants/ProcessPayments_variant_2.vue";
import PaymentSummaryTab from "@/components/membership-area/premium-plans/PaymentSummaryTab.vue";
//import ServicesAdvantages from "@/components/membership-area/premium-plans/ServicesAdvantages.vue";
//import PlansBenefits from "@/components/membership-area/premium-plans/PlansBenefits.vue";
//import PaymentMethods from "@/components/membership-area/premium-plans/PaymentMethods.vue";
import { mapGetters } from "vuex";
import store from '../../../store';
//import jwt from "jsonwebtoken";
import Vue from "vue";
import LoadScript from "vue-plugin-load-script";

Vue.use(LoadScript);


export default {
  name: "PremiumPlans",
  components: {
    PlansChoices,
    ProcessPayments,
    ProcessPaymentsVar2,
    PaymentSummaryTab,
    //ServicesAdvantages,
    //PlansBenefits,
    //PaymentMethods,
    MembershipHeader,
    //MembershipFooter
  },
  beforeRouteEnter (to, from, next) {
   
   if(store.getters.userRole.includes('ROLE_PREMIUM')){
     next('/membership-area/home');
      //if(to.query.signup == 1) next('/membership-area/home');
      //else next(from);
    }else{
      next();
    }
  },
  created() {
    this.loadPlans();
  },
  mounted() {

    if(this.$route.path == '/premium-plans' || this.$route.path == '/premium-plans/'){
      this.$router.replace('/premium-plans/plans');
    }
   
    window.scrollTo(0,0);

    this.checkMembership();

    if(this.$route.params.purchase && this.$store.getters.planSelected){
    this.tabIndex =1;
    }
    if(this.$route.path.indexOf("/premium-plans/plans") != -1){
      this.tabIndex = 0;
     }


  },
  data() {
    return {
      premiumRole: false,
      showPlans: false,
      wizard: true,
      featureModalShow: false,
      tabIndex: 0,
      selectedMethod: "CARD",
      processor: '',
      secForm: null,
      secData: {
        securionPrimaryKey: null,
        securionFallbackKey: null,
        countPaymentAttempt:1
      },
      sepa_data: null,
      magniusDataSummary: null,
      celerisDataSummary: null,
      showSummaryTab: false,
      isPremium: true,
      backLock: false,
      mailTriggerSent: false,
      checkoutId: '',
      loadedWindowHeight: window.innerHeight,
      securionFallback: false,
      retrySecurion: false,
      countPaymentAttempt: 1,
    };
  },
  computed: {
    ...mapGetters(["getPlans", "getActivePlan", "user", "isMobile", "isDEV"]),
    CreditCardProcessor: function(){

      if(this.getActivePlan.plansPrice.tariffIds.CelerisPay) {
        return 'celeris';
      }

      if( 
        //true //Temporarily lock Magnius
        !this.getActivePlan.plansPrice.tariffIds.Magnius
        //Block magnius for all CH domains
        //|| domain == 'ch'
      ){
        return 'sec';
      }else 
      {
        return 'mag';
      }
      
    },
    paymentVariant: function(){
      
      if(this.$store.getters.isMobile) return 2;

      if(
        this.domain == 'nz' || this.domain == 'au' || this.domain == 'ch' || this.domain == 'za'
      ) return 1;
      else {
        return 2;
      }

    },
    skipPaymentOptions: function(){
      return true;
    },
    desktopPlansVariant: function() {
        return 1; // CASUAL HAS ONLY 1
        /*
      if(
        domain == 'at' ||
        domain == 'au' ||
        domain == 'ch' ||
        domain == 'cz' ||
        domain == 'nz' ||
        domain == 'pl'
      ){
        //New Variant
          return 2;
      }
      else if(
        domain == 'de' ||
        domain == 'uk' ||
        domain == 'za'
      ){
        //Old Variant
        return 1;
      }
      else {
        return 1;
      }
      */

    },
    mobilePlansVariant: function() {

      return 3; // CASUAL HAS ONLY 1
      /*
      if(
        domain == 'de' ||
        domain == 'at' ||
        domain == 'au' ||
        domain == 'pl' ||
        domain == 'za'
      ){
        //New Variant
        return 2;
      }
      else if(
        domain == 'ch' ||
        domain == 'nz' ||
        domain == 'cz' ||
        domain == 'uk'
      ){
        //Old Variant
        return 1;
      }
      else {
        return 1;
      }
      */

    },
    enableSEPA: function(){

      //Disable SEPA when no tariffs are given
      if(
          !this.getActivePlan.plansPrice.tariffIds.SEPAExpress &&
          !this.getActivePlan.plansPrice.tariffIds.Micropayment 
        ) return false;

      //Enable SEPA for selected countries
      if( domain == 'de' || domain == 'at') return true;

      //Enable SEPA for testing on DEV server
      else if(this.isDEV) return true;

      //Disable SEPA by default when no other criteria is matched
      else return false;

    }

  },
  watch:{
    $route(to, from) {
      console.log('WATCH ROUTER TO', to);
      console.log('WATCH ROUTER FROM', from);
      //console.log('backLock', this.backLock)

      if(this.backLock) {
        //console.log('BACK NAVIGATION')
        if(from.path == '/premium-plans/plans'){
          this.$router.go(-2);
          return false;
        }

      }

      if(to.path.indexOf("/premium-plans") != -1){
      this.backLock = false;
      this.tabIndex = 0;
      if(
          (to.path == '/premium-plans' || to.path == '/premium-plans/') && from.path != '/premium-plans/plans'
        ){
          this.$router.replace({ path:'/premium-plans/plans', query: {direct: '1'} });
        }
      }

      if(to.path.indexOf("/premium-plans/plans") != -1){
      this.backLock = false;
      this.tabIndex = 0;
      }

      if(to.path.indexOf("/premium-plans/form") != -1){
        this.backLock = false;
        if(this.skipPaymentOptions){
          this.tabIndex = 1;
          this.selectedMethod = 'CARD';
        }
        else this.tabIndex = 2;
      }
      if(to.path.indexOf("/premium-plans/select") != -1 ){
      this.backLock = false;
      this.tabIndex = 1;
      if(this.skipPaymentOptions) {
        if(from.path.indexOf("/premium-plans/form") == -1){
          this.selectedMethod = 'CARD';
          this.tabIndex = 1;
          this.$router.replace('/premium-plans/form');
        }else{
          this.tabIndex = 0;
          this.$router.replace('/premium-plans/plans');
        }
      }
      }
      if(to.path.indexOf("/premium-plans/summary") != -1){

        if(!this.isMobile){
          this.backLock = false;
          this.tabIndex = 1;
        }else{
          this.backLock = false;
          this.tabIndex = 3;
        }
      }

    },
    tabIndex: function(){
      console.log('WATCH TABS', this.tabIndex);
      if(this.tabIndex == 1){
        if(!this.mailTriggerSent){
        this.$axios({ url: process.env.VUE_APP_URL + '/api/payments/pp2', method: "GET" })
          .then(() => {
            this.mailTriggerSent = true;
           });
        }
      } 
      
      $('.nav-item').removeClass('finished');
      $($('.nav-item')[this.tabIndex]).prevAll().addClass('finished');

      if(this.tabIndex == 1 && this.isMobile && !this.skipPaymentOptions){
        this.selectedMethod = 'CARD';
      }

      window.scrollTo(0,0);

      if(this.tabIndex == 3){
        setTimeout(() => {
          if(this.paymentVariant == 2 ) {
            console.log('PRICING SCROLL')
            window.scrollTo(0,document.querySelector('#pricingSection').offsetHeight - 10);
          }else {
            window.scrollTo(0,0);
          }
        }, 100);
      }

    }
  },

  methods: {
    
    loadPlans(){
      this.$store.dispatch("getPlans").then((res)=>{
        console.log('getPlans pp', res);
        if(res.length == 0) this.$store.dispatch("setErrorVisibility", true);
        if(res != 'error'){
          this.showPlans = true;
          window.scrollTo(0,0);
        }else{
          this.$store.dispatch("setErrorVisibility", true);
        }
      });
    },
    checkMembership(){
      if(this.$route.query.signup == 1 || this.$route.query.direct == 1){
        this.isPremium = false;
        this.featureModalShow = false;
      }
      else{
        this.isPremium = false;
        this.featureModalShow = true;
        this.$router.replace({ path: '', query: { direct: '1' }});
      }

    },
    selectMethod(method){
      console.log('selectMethod', method)
      this.selectedMethod = method;
    },

    //PAYMENT FUNCTIONS / MERGED FROM PROCESS
    
    // MOBILE PAYMENT SUMMARY

    showSummary(data){
      setTimeout(()=> {

        if(this.selectedMethod == "SEPA"){

          console.log('SEPA SUMMARY', data);
          this.sepa_data = data.sepa_data;
          
        }else if(this.selectedMethod == "CARD") {

          if(this.CreditCardProcessor == "sec"){
            
            console.log('SHIFT4 SUMMARY', data);
            this.checkoutId = data.checkoutId;
            this.secForm = data.form;
            this.secData.securionPrimaryKey = data.securionPrimaryKey
            this.secData.securionFallbackKey = data.securionFallbackKey
            this.secData.countPaymentAttempt = data.countPaymentAttempt

          }else if(this.CreditCardProcessor == "mag"){

            console.log('MAGNIUS SUMMARY', data);
            this.magniusDataSummary = data.data;

          }else if(this.CreditCardProcessor == "celeris"){

            console.log('CELERIS SUMMARY', data);
            this.celerisDataSummary = data.data;

          }

        }

        this.tabIndex = 3;
        this.showSummaryTab = true;
        this.$router.push('/premium-plans/summary');

        document.addEventListener('scroll', () => {
          console.log('loadedWindowHeight', this.loadedWindowHeight, window.innerHeight);
          if(this.loadedWindowHeight <= window.innerHeight){
            var offset = window.innerHeight - this.loadedWindowHeight;
            if(offset < 10 ) offset = 10;
            $('#formWrapper').css('padding-bottom',  offset+'px' );
          }
        });

      },500);
    },

    //PAYMENT NAVIGATION 
    nextTab() {
      this.tabIndex = 1;
      this.eventHub.$emit("update", "");
      //if(this.$route.query.signup == 1) this.$router.push('/premium-plans/purchase?signup=1')
      //else if(this.$route.query.direct == 1) this.$router.push('/premium-plans/purchase?direct=1')
      //else this.$router.push('/premium-plans/purchase')
      //this.webgainRetargeting();

      var realPrice = this.getActivePlan.plansPrice.fullPrice;
      if(parseFloat(this.getActivePlan.plansPrice.initialFullPrice)) {
        realPrice = this.getActivePlan.plansPrice.initialFullPrice;
      }
      realPrice = (realPrice/1.2)-(realPrice*0.06);
      realPrice = Math.floor(realPrice * 100)/100

      //TIK TOK EVENT
      if(typeof window.ttq != 'undefined'){
          window.ttq.track('InitiateCheckout',{
              content_type: 'product',
              currency: this.getActivePlan.plansPrice.currencyISO,
              value: realPrice,
              content_id: this.user.user_id,
            }
          );
        }else {
          var tiktokint = setInterval( ()=>{
            console.log('tiktok int');
            if(typeof window.ttq != 'undefined'){
              console.log('tiktok found');
              clearInterval(tiktokint);
              window.ttq.track('InitiateCheckout',{
                  content_type: 'product',
                  currency: this.getActivePlan.plansPrice.currencyISO,
                  value: realPrice,
                  content_id: this.user.user_id,
                }
              );
            }
          }, 200)

          setTimeout(()=>{
            clearInterval(tiktokint);
          }, 10000)

        }

      if(!this.isMobile) this.$router.push('/premium-plans/summary');
      else {
        if(this.skipPaymentOptions) this.$router.push('/premium-plans/form');
        else this.$router.push('/premium-plans/select');
      }
      this.featureModalShow = false;
      this.$store.dispatch('selectedPlan');
    },

    goBack(){
      var isSummaryTab = false
      if(this.skipPaymentOptions && this.tabIndex == 2) isSummaryTab = true;
      else if(!this.skipPaymentOptions && this.tabIndex == 3) isSummaryTab = true;


      if(!this.backLock && !isSummaryTab){
        this.backLock = true;
        /*
        if(this.$route.path == '/premium-plans/plans') this.$router.go(-2);
        else this.$router.go(-1);
        */

         this.$router.go(-1);

      }else if(!this.backLock && isSummaryTab){
        //this.$router.push('/premium-plans/plans');
        if(this.skipPaymentOptions) this.$router.go(-2);
        else this.$router.go(-3);
      }

    },

  },
};
</script>

