export default {
    set_plans(state, payload){
        state.plans = payload;
    },
    set_product(state, payload){
        state.product = payload;
    },
    set_planState(state, payload){
        state.planSelected = payload;
    },
    prefillData: (state, payload) => state.prefillData = payload,
    setActivePlan: (state,payload) => {
        state.activePlan = payload
    },
    set_descriptor: (state, payload) => state.descriptorText = payload,
    setPaymentLocked: (state, payload) => state.paymentLocked = payload,
    setPaymentLockedError: (state, payload) => state.paymentLockedError = payload,
};