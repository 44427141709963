<template>
  <div>
    <!-- {{this.$route.params.checkoutID}}  -->

    <!-- <br> {{this.$route.query.id}} -->
  </div>
</template>

<script>
// 5454 5454 5454 5454 true
// 5555555555554444 false
export default {

  methods: {
    proceedPayment(dispatchFunction) {
      this.$store
        .dispatch(dispatchFunction, this.$route.query.id)
        .then((resp) => {
          if (resp.data.success == true && resp.data.token) {
            localStorage.setItem("token", resp.data.token);
          console.log('resp', resp);
          this.$router.push({ path: "/payments-success-info", query: { cid: this.$route.query.id } });
         
              if(resp.data.detailsEntered == true){
                this.$router.push({ path: "/payments-success", query: { cid: this.$route.query.id } });
              } else if(resp.data.detailsEntered == false) {
                this.$router.push({ path: "/payments-success-info", query: { cid: this.$route.query.id } });
              }

          } else {

              this.$router.push("/payments-error");

          }
        })
        .catch((err) => {
          console.log(err)
          this.$router.push("/payments-error");
        });
    },
  },
  created() {
    console.log('COMPLETE PAYMENT ROUTE', this.$route);
    if(this.$route.params.resourcePath == 'CelerisPay'){
      this.proceedPayment("completePaymentCeleris");
    }
    else if(this.$route.query.resourcePath && this.$route.query.resourcePath.indexOf('/registration') != -1){
      this.proceedPayment("completePaymentRegistered");
    } 
    else {
      this.proceedPayment("completePayment");
    }

  },
};
</script>
