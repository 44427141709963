<template>
  <div id="CelerisPayment" :class="{loading: !sdkInstance}">
    <div id="celerisPaymentWrapper" v-show="sdkInstance" >
      <form 
        method="post" 
        id="payment-form"
        class="wpwl-form wpwl-form-card celeris"
        @submit.prevent="submitCeleris"
        
      >
        <span class="payment-errors">{{ paymentErrors }}</span>
        
        <div class="form-group wpwl-group">
          <div id="CARD_NUMBER-ContainerID" class="wpwl-control"></div>
        </div>
        <div class="form-group wpwl-group">
          <div id="CARD_HOLDER_NAME-ContainerID" class="wpwl-control"></div>
        </div>
        <div class="form-group wpwl-group">
          <div id="EXPIRY_FIELD-ContainerID" class="wpwl-control"></div>
        </div>
        <div class="form-group wpwl-group">
          <div id="CARD_CVV-ContainerID" class="wpwl-control"></div>
        </div>

        <button class="wpwl-button" type="submit" name="submitButton" :disabled="celerisLocked">
          {{paymentButtonText}}
          <i class="fa fa-spinner fa-spin"></i>
        </button>
      </form>
    </div>
    <div class="spinnerWrapper" v-show="!sdkInstance">
      <div class="spinner"></div>
    </div>
  </div>
</template>
<script>
//Celeris Payment Integration

import Vue from "vue";

import LoadScript from "vue-plugin-load-script";
import Celeris_helpers from "@/helpers/payments/Celeris_functions";
import { mapGetters } from "vuex";


Vue.use(LoadScript);

export default {
    name: "CelerisPayment",
    props: [
      'paymentButtonText'
    ],
    data() {
      return {
        celerisIframe: null,
        celerisToken: null,
        celerisRequestId: null,
        celerisLocked: false,
        sdkInstance: null,
        paymentErrors:null,
        countPaymentAttempt: 1,
      }
    },
    watch: {
      
    },
    computed: {
      ...mapGetters(["user","getActivePlan", "isMobile"]),
      showCardHolder: function(){
      return true;
    },
    },
    created: function () {
      console.log('Celeris Payment Integration loaded')
      this.initCeleris();
      //Set correct descriptor text
      this.$store.dispatch("setDescriptor", { brand: host, payment: 'celeris'});
    },
    destroyed() {
      this.celerisReset();
    },
    methods: {
      initCeleris() {
        Vue.loadScript('../js/celeris-sdk/sdk.bundle.v1.3.3.js').then(()=>{

          console.log('Celeris SDK loaded');
          //SEND TO HELPER FUNCTION 
          Celeris_helpers._initialize({}, this)

         });
        

      },

      celerisValidate(data){
        //SEND TO HELPER FUNCTION 
        return Celeris_helpers._validation(data, this)
      },

      tokenizeCeleris(){
        
        //SEND TO HELPER FUNCTION 
        return Celeris_helpers._tokenize({}, this)

      },
      async submitCeleris(evt){

        //SEND TO HELPER FUNCTION 
        Celeris_helpers._submitData({evt: evt}, this)
        
      },
      submitCelerisMobile(data){
        console.log('submitMagniusMobile', data)
        if(typeof data == 'undefined' || !data) return false;

        //SEND TO HELPER FUNCTION 
        Celeris_helpers._finalizePayment({paymentData: data}, this)

      },
      
      celerisReset(){
        console.log('celerisReset');
        
      },
      
    },
  }

</script>
<style lang="scss" scoped>

#CelerisPayment.loading {
  min-height: 400px;
}

</style>