<template>
  <div id="processPayments" class="variant2">
    <div id="pricingSection" v-if="!this.mobileView">
      <h3>{{ $t($i18n.locale + '.keys.premiumPlans.processPayments.pricingDetails_claim') }}</h3>

      <div class="pricingDetails">
        <h4>{{ $t($i18n.locale + '.keys.premiumPlans.processPayments.pricingDetails_headline') }}</h4>
          <p>
            <span v-if="domain != 'cz'">{{ this.infoTextDuration }},&nbsp;</span>
            <span v-else >{{ $t($i18n.locale + '.keys.premiumPlans.processPayments.pricingDetails_sum') }}&nbsp;</span>
            <span>
              <template v-if="selectedLanguage == 'en'">
              {{ this.getActivePlan.plansPrice.currencySign }}{{ this.getActivePlan.plansPrice.visiblePrice }}.{{ this.getActivePlan.plansPrice.visiblePriceCents }}
              </template>
              <template v-else>
              {{ this.getActivePlan.plansPrice.visiblePrice }},{{ this.getActivePlan.plansPrice.visiblePriceCents }}{{ this.getActivePlan.plansPrice.currencySign }}
              </template>
            </span>

          </p>
          <p>
            <span v-if="domain == 'cz'">{{ this.infoTextDuration }},&nbsp;</span>
            <span v-else >{{ $t($i18n.locale + '.keys.premiumPlans.processPayments.pricingDetails_sum') }}&nbsp;</span>
            <template v-if="selectedLanguage == 'en'">
              <span v-if="this.getActivePlan.plansPrice.discount && this.getActivePlan.plansPrice.initialPrice">
                {{ this.getActivePlan.plansPrice.currencySign + this.getActivePlan.plansPrice.initialFullPrice }}
              </span>
              <span v-else>{{ this.getActivePlan.plansPrice.currencySign + this.getActivePlan.plansPrice.fullPrice}}</span>
            </template>
            <template v-else>
              <span v-if="this.getActivePlan.plansPrice.discount && this.getActivePlan.plansPrice.initialPrice">
                {{ this.getActivePlan.plansPrice.initialFullPrice.replace('.', ',') + this.getActivePlan.plansPrice.currencySign }}
              </span>
              <span v-else>{{ this.getActivePlan.plansPrice.fullPrice.replace('.', ',') + this.getActivePlan.plansPrice.currencySign }}</span>
            </template>

          </p>
      </div>
    </div>
    <div class="processPaymentsWrapper container-membership" >
      <div class="additionalWrapper">
        <div class="paymentsDataWrapper" :class="{ paymentLocked: paymentLocked }">
          <div v-if="paymentLocked" class="paymentLocked">
            <img src="@/assets/images/sandclock-icon.svg" alt="lock icon" height="50" />
            <p>
              {{ paymentLockedError }}
            </p>
          </div>
          <template v-else>
          <header class="paymentsDataHeader clearfix">
            <div
              v-if="true"
              class="column"
              @click="
                loadProduct('CARD');
              "
              v-bind:class="{ selectedMethod: paymentMethod == 'CARD', noSEPA: !enableSEPA }"
            >
              <h1
                style="text-transform: uppercase;"
                :contenteditable="editable"
                @blur="
                  $t(
                    $i18n.locale
                  ).keys.premiumPlans.processPayments.creditCard =
                    $event.target.innerText
                "
              >
                <span class="icons">
                  <img src="@/assets/images/MasterCard2.png" alt="Master card">
                  <img src="@/assets/images/Visa2.png" alt="VISA Icon">
                </span>
                <span class="title">
                  {{
                    $t($i18n.locale).keys.premiumPlans.processPayments.creditCard
                  }}
                </span>
              </h1>
            </div>
            <div
              v-if="enableSEPA"
              class="column"
              @click="
                loadProduct('SEPA');
              "
              v-bind:class="{ selectedMethod: paymentMethod == 'SEPA', noSEPA: !enableSEPA }"
            >
              <h1 style="text-transform: uppercase;">
                <span class="icons">
                  <img src="@/assets/images/SEPA2.png" alt="SEPA">
                  <img src="@/assets/images/EC.png" alt="EC Icon">
                </span>
                <span class="title">
                  SEPA
                </span>
              </h1>
            </div>
          </header>


          <div  class="paymentWrapperNew">
            <div class="spinnerWrapper" v-if="!showForm">
              <div class="spinner"></div>
            </div>
            <div id="paymentFormsWrapper" >
              <div class="PaymentCard" v-if="showForm">

                <Shift4Payment v-if="showCardForm && processor == 'sec'"
                  :payment-button-text="paymentButtonText" 
                  @showSummary="showSummary"
                />

                <SEPAPayment v-if="showSEPAForm"
                  :payment-button-text="paymentButtonText" 
                  @showSummary="showSummary"
                />

                <MagniusPayment v-if="showCardForm && processor == 'mag'"
                  :payment-button-text="paymentButtonText" 
                  @showSummary="showSummary"
                />

                <CelerisPayment v-if="showCardForm && processor == 'celeris'"
                  :payment-button-text="paymentButtonText" 
                  @showSummary="showSummary"
                />

                <div class="sslMessage" v-if="showForm">
                  <img src="@/assets/images/locker-icon.svg" alt="Locker icon" />&nbsp;
                  <span
                    v-langErr
                    :contenteditable="editable"
                    @blur="
                      $t($i18n.locale).keys.premiumPlans.processPayments.siteSecured =
                        $event.target.innerText
                    "
                    >{{
                      $t($i18n.locale).keys.premiumPlans.processPayments.siteSecured
                    }}</span
                  >
                </div>
                <div class="badgeWrapper-mobile">
                  <img
                  class="sslBadge"
                  src="@/assets/images/positive-ssl.png"
                  alt="SSL badge"
                />
                  <p
                  v-langErr
                        :contenteditable="editable"
                        @blur="
                          $t($i18n.locale).keys.premiumPlans.processPayments.certInfo =
                            $event.target.innerText
                        "
                  >
                    {{
                      $t($i18n.locale).keys.premiumPlans.processPayments.certInfo
                    }}
                  </p>

                </div>
              </div>
            </div>
          </div>
          </template>


        </div>

        <!--
        <div class="additionalInfo" v-if="mobileView">
              <p v-html="$t($i18n.locale + '.keys.premiumPlans.processPayments.additionalInfo', {
                backLink: (this.$route.query.signup == 1) ? '/premium-plans/?signup=1':'/premium-plans/'
              })"></p>
        </div>
        -->

        <div class="paymentAdvantagesWrapper" v-if="!mediumView">
          <!--
          <p
            class="chosenTitle"
            v-langErr
            :contenteditable="editable"
            @blur="
              $t($i18n.locale).keys.premiumPlans.processPayments.yourChosen =
                $event.target.innerText
            "
          >
            {{ $t($i18n.locale).keys.premiumPlans.processPayments.yourChosen }}:
          </p>
          -->
          <h1
            class="chosenPlanTitle"
            v-langErr
            :contenteditable="editable"
            @blur="
              $t(
                $i18n.locale
              ).keys.premiumPlans.processPayments.premiumMembership =
                $event.target.innerText
            "
          >
            {{
              $t($i18n.locale).keys.premiumPlans.processPayments
                .premiumMembership
            }}
          </h1>

          <div class="membershipAdvantagesWrapper">
            <div class="membershipAdvantage for" v-for="(advantage, index) in advantagesBullets" :key="`advantage-${index}`">
              <div class="leftAdvantageWrapper">
                <img :src="require('@/assets/images/' + $store.getters.assetsPath + 'check-green.svg')" alt="Check icon" />
                <p>{{ advantage }}</p>
              </div>
            </div>
          </div>
          

          <div class="badgeWrapper-desktop">
            <p v-langErr :contenteditable="editable" @blur="$t($i18n.locale).keys.premiumPlans.processPayments.certInfo = $event.target.innerText">
              {{ $t($i18n.locale).keys.premiumPlans.processPayments.certInfo }}
            </p>
            <img
              class="sslBadge"
              src="@/assets/images/positive-ssl.png"
              alt="SSL badge"
            />
          </div>

        </div>
      </div>
      <div class="bottomWrapper clearfix">
        <div
          class="infoTextWrapper"
          v-if="!mediumView"
          v-langErr
          :class="domain"
        >
            <div v-if="infoTextAB" class="mainInfoText">
              {{ $t($i18n.locale + '.keys.premiumPlans.processPayments.mainInfoText') }}

            </div>
            <div v-if="infoTextAB" class="showInfoTextBtn" @click.prevent.stop="toggleInfoText($event)" ref="showInfoTextBtn">
              <span>{{ $t($i18n.locale + '.keys.premiumPlans.processPayments.showInfoTextBtn') }}</span><i class="fa fa-caret-right"></i>
            </div>
            <div class="subInfoText" v-if="showInfoText || !infoTextAB">
              <span
              v-if="this.getActivePlan.plansPrice.discount && this.getActivePlan.plansPrice.initialPrice"
              v-html="$t($i18n.locale +'.keys.premiumPlans.processPayments.thisOfferFullPromotion',
                  {
                    duration: this.infoTextDuration,
                    fullPrice: this.getActivePlan.plansPrice.currencySign + this.getActivePlan.plansPrice.fullPrice.replace('.', ','),
                    initialFullPrice: this.getActivePlan.plansPrice.currencySign + this.getActivePlan.plansPrice.initialFullPrice.replace('.', ','),
                    productName: fullDomain,
                    supportEmail: $store.getters.supportEmail,
                    descriptorText: $store.getters.getDescriptorText,
                  })"
              ></span>
              <span
              v-else
              v-html="$t($i18n.locale +'.keys.premiumPlans.processPayments.thisOfferFull',
                    {
                      duration: this.infoTextDuration,
                      fullPrice: this.getActivePlan.plansPrice.currencySign + this.getActivePlan.plansPrice.fullPrice.replace('.', ','),
                      productName: fullDomain,
                      supportEmail: $store.getters.supportEmail,
                      descriptorText: $store.getters.getDescriptorText,
                    })"
              ></span>
            </div>
        </div>

          <div  id="termsModalTrigger" style="display:none;" @click="modalTermsShow = true"></div>
          <b-modal id="termsModal" v-model="modalTermsShow" hide-footer hide-header>
            <div class="exit-button-terms" @click="modalTermsShow = false">
              <img src="@/assets/images/Close-cross-Grey.svg" alt="Exit icon" />
            </div>
            <div class="termsWrapper">
              <div
                v-html="termsAndPoliciesText.termsOfService"
              ></div>
            </div>
          </b-modal>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import Vue from "vue";
import VueCardFormat from 'vue-credit-card-validation';
//import iban from 'iban';

import SEPAPayment from "@/components/membership-area/premium-plans/paymentSystems/SEPAPayment";
import Shift4Payment from "@/components/membership-area/premium-plans/paymentSystems/Shift4Payment";
import MagniusPayment from "@/components/membership-area/premium-plans/paymentSystems/MagniusPayment";
import CelerisPayment from "@/components/membership-area/premium-plans/paymentSystems/CelerisPayment";

Vue.use(VueCardFormat);

export default {
  name: "processPayments",
  mixins: [
    //MagniusPaymentMixin,
    //Shift4PaymentMixin,
    //SEPAPaymentMixin
  ],
  props: [
    "selectedMethod",
    "processor",
    "enableSEPA"
  ],
  components: {
    SEPAPayment,
    Shift4Payment,
    MagniusPayment,
    CelerisPayment
  },
  data() {
    return {
      BASE_URL: process.env.VUE_APP_URL,
      brands: null,
      showForm: false,
      showCardForm: true,
      showSEPAForm: false,
      componentKey: 0,
      checkoutId: '',
      termsAndPoliciesText: this.$store.getters.globalTermsAndPolicies,
      modalTermsShow: false,
      
      showInfoText: false,
      countPaymentAttempt: 1,
      formLocked: false,
      paymentErrors:null,
      advantagesBullets: [
        this.$t(this.$i18n.locale + '.keys.premiumPlans.plansInfo.plansBenefits.unlimitedCommunication'),
        this.$t(this.$i18n.locale + '.keys.premiumPlans.plansInfo.plansBenefits.unlimitedReading'),
        this.$t(this.$i18n.locale + '.keys.premiumPlans.plansInfo.plansBenefits.seeAllProfileVisitors'),
        this.$t(this.$i18n.locale + '.keys.premiumPlans.plansInfo.plansBenefits.viewAllPhotos'),
        this.$t(this.$i18n.locale + '.keys.premiumPlans.plansInfo.plansBenefits.readReceiptsForMessages'),
      ],
      
    };
  },
  watch: {
   
    cardCvc: function(val){
      if(val.length > 4) this.cardCvc = val.slice(0, -1);
    },
    cardNumber: function(val){
      setTimeout(() => {
        if(val != this.$refs._cardNumber.value) this.cardNumber = this.$refs._cardNumber.value;
        let plainValue = val.replace(/\s/g, '');
        if(plainValue.length > 16){
          this.cardNumber = val.slice(0, -1);
        }
      }, 1);
    },
    selectedMethod: function(val){
      this.showForm = false;
      this.loadProduct(val);

      if(val == 'SEPA'){
        this.brands = 'SEPA';
      }else{
        this.brands = 'CC';
        // if(this.getProduct.data)  this.brands = this.getProduct.data.brands;
      }
    }

  },
  computed: {
    ...mapGetters(["editable", "getPlans", "getProduct", "user","getActivePlan", "paymentLocked", "paymentLockedError"]),
    mobileView:function() {return this.$store.getters.isMobile},
    mediumView:function() {return this.$store.getters.isMobile},
    paymentMethod: function() {

      let val = this.selectedMethod;
      
      return val;
    },
    showPriceInfoAB: function(){

      return true;

      /*
      if(this.user.user_id%2 == 0){
         return true;
       }else{
         return false;
       }
       */
    },
    infoTextAB: function(){
      return false;
      // if(this.user.user_id%2 == 0){
      //   return true;
      // }else{
      //   return false;
      // }

    },
    
    
    selectedLanguage: function() {
      return this.$store.getters.selectedLanguage;
    },
    light: function() {
      return this.getPlans[0];
    },
    classic: function() {
      return this.getPlans[1];
    },
    comfort: function() {
      return this.getPlans[2];
    },
    domain: function(){

      console.log('DOMAIN', domain);

      return domain;


    },
    fullDomain: function(){
      
      var finalUrl = host + '.' + domain;
      if(domain == 'uk') finalUrl = host + '.co.uk';
      else if(domain == 'nz') finalUrl = host +  '.co.nz';
      else if(domain == 'za') finalUrl = host +  '.co.za';
      else if(domain == 'au') finalUrl = host +  '.com.au';
      
      //return host + tld
      return finalUrl;
    },
    infoTextDuration: function(){
      return this.getActivePlan.plansInfoBody.headlinePeriod
    },
    paymentButtonText: function(){
      if ( this.mobileView )
      {
        return this.$t(this.$i18n.locale +'.keys.premiumPlans.processPayments.purchase');
      } else {
        return this.$t(this.$i18n.locale +'.keys.premiumPlans.processPayments.processPaymentButton');
      }
    },
  },
  mounted() {
    console.log('payment mounted', this.processor)
    if(this.processor == 'sec'){
      //this.initSecurionPay();
    }else if(this.processor == 'mag'){
      //this.initMagnius();
    }
    console.log('IBAN------------------', this.iban_validator)
  },
  destroyed() {
    this.componentKey++;
  },
  created() {
    
    this.eventHub.$on("update", () => {
      console.log('UPDATE PROCESS');
      window.scrollTo(0, 0);
      this.showForm = false;
      this.loadProduct(this.paymentMethod);
    });
  },
  methods: {

    //MOBILE SUMMARY EMITTER
    showSummary(data){
      this.$emit('showSummary', data);
    },
    
    //MISC METHODS 
    toggleInfoText($event) {
      console.log('toggleInfoText', $event)
      this.$refs.showInfoTextBtn.remove();
      this.showInfoText = !this.showInfoText;
    },
    
    loadProduct(method) {
      if(method == '') return true;
      this.showForm = false;
      if(method == 'SEPA'){
        this.showCardForm = false;
        this.showSEPAForm = true;
      }else{
        this.showCardForm = true;
        this.showSEPAForm = false;
      }
      console.log('selectMethod emit')
      this.$emit('selectMethod', method);

      this.showForm = true;
      

    },
  },
};
</script>

<!-- Payment widget customization -->

<style lang="scss">

#paymentIFrame, #magnius3DSframe {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: none;
  background: rgb(255, 255, 255);
  z-index: 99999;
  border: none;
}

</style>
