<template>

  <div
    id="resetPass"
        class="resetPasswordContainer"
  >
    <div
      class="linkHeader"
      :class="{
        superadmin: $store.getters.userRole.includes('ROLE_PAGE_TRANSLATOR'),
        editngMode: $store.getters.editable,
      }"
    >
        <img
          class="logoLinks"
          :src="$store.getters.mainLogos.desktop"
          alt="Logo"
        />
    </div>
    <div class="container-main">
      <div class="containerChangePass" :class="{ mobileView: mobileView }">
        <div class="messageContainer">
          <p
            class="resetMessage"
            v-langErr
            :contenteditable="editable"
            @blur="
              $t($i18n.locale).keys.auth.resetPassword = $event.target.innerText
            "
          >
            {{ $t($i18n.locale).keys.auth.resetPassword }}
          </p>
          <p class="changePassTitle">
            <!-- <span class="passwordName">{{name}},</span> -->
            <span
              v-langErr
              :contenteditable="editable"
              @blur="
                $t($i18n.locale).keys.auth.hereYouCan = $event.target.innerText
              "
              >{{ $t($i18n.locale).keys.auth.hereYouCan }}</span
            >
          </p>
          <p
            class="rememberPassTitle"
            v-langErr
            :contenteditable="editable"
            @blur="
              $t($i18n.locale).keys.auth.rememberThat = $event.target.innerText
            "
          >
            {{ $t($i18n.locale).keys.auth.rememberThat }}
          </p>
          <!-- <label
            class="newPassLabel"
            for=""
            v-langErr
            :contenteditable="editable"
            @blur="
              $t($i18n.locale).keys.auth.newPassword = $event.target.innerText
            "
            >{{ $t($i18n.locale).keys.auth.newPassword }}:</label -->
          <!-- > -->
          <input
            v-model="newPassword1"
            class="newPassInput"
            :placeholder="$t($i18n.locale).keys.auth.newPassword"
            type="password"
            v-on:keyup="checkPassword"
          />
          <!-- <label
            class="newPassLabel"
            for=""
            v-langErr
            :contenteditable="editable"
            @blur="
              $t($i18n.locale).keys.auth.newPassword = $event.target.innerText
            "
            >{{ $t($i18n.locale).keys.auth.repeatPassword }}:</label -->
          <!-- > -->
          <input
            v-model="newPassword2"
            class="newPassInput"
            :placeholder="$t($i18n.locale).keys.auth.repeatPassword"
            type="password"
            v-on:keyup="checkPassword"
          />
          <p
            class="passwordError"
            v-if="passError"
            v-langErr
            :contenteditable="editable"
            @blur="
              $t($i18n.locale).keys.auth.doNotMatch = $event.target.innerText
            "
          >
            {{ $t($i18n.locale).keys.auth.doNotMatch }}
          </p>
          <button @click="changedPassword" class="saveBtn" :disabled="isLoading">
            {{ $t($i18n.locale).keys.generic.save }}
          </button>
          <input
            v-if="editable"
            v-model="$t($i18n.locale).keys.generic.save"
            type="text"
          />
        </div>
      </div>
    </div>

    <MembershipFooter />
  </div>
</template>

<script>
// import jwt from "jsonwebtoken";
import MembershipFooter from "@/components/membership-area/MembershipFooter.vue";
import axios from "axios";
const BASE_URL = process.env.VUE_APP_URL;
export default {
  components: {
    MembershipFooter,
  },

  data() {
    return {
      showTemplate: false,
      name: "name",
      // name: jwt.decode(this.$route.params.token).email,
      mobileView: false,
      newPassword1: "",
      newPassword2: "",
      passError: false,
      isLoading: false,
      
    };
  },
  computed: {
    editable: function() {
      return this.$store.getters.editable;
    },
    validPassword: function() {
      if (
        this.newPassword1.length < 6 ||
        this.newPassword1 !== this.newPassword2
      ) {
        return false;
      } else {
        return true;
      }
    },
    siteId: function(){
      return this.$store.getters.siteId
    },
  },

  methods: {
  changeLanguage() {

    let localeToSet = 'en';

      let url = window.location.origin.split(".");
      let domain = url[url.length - 1];
      if (domain == "de" || domain == "ch" || domain == "at") {
        localeToSet = "de";
      } else {
        localeToSet = "en";
      }

      console.log(localeToSet);

      this.$store.dispatch("changeLanguage", {
        locale: localeToSet,
        keys: this.$t(this.$i18n.locale),
      });
    },



    setNewPassword(data) {
      axios
        .post(BASE_URL + `/password/reset/${this.$route.params.token}`, data)
        .then((resp) => {
          return resp.data;
        })
        .then((data) => {
          this.isLoading = false;
          if (data) {
            axios
              .post(`${BASE_URL}/auth/login`, data.data)
              .then(function(response) {
                if (response.status == 200) {
                  let token = response.data.token;
                  window.location.href = `/magicLink/${token}`;
                }
              });
          }
        })
        .catch(error => {
          this.isLoading = false;
          console.log(error);
        });
    },

    changedPassword() {

      if(this.isLoading) return;

      this.isLoading = true;

      let password = {
        password: this.newPassword1,
        passwordConfirm: this.newPassword2,
        siteId: this.siteId,
      };
      if (!this.passError && this.validPassword) {
        // alert(password)
        this.setNewPassword(password);
      } else {
        alert("enter a valid password");
        this.isLoading = false;
      }
    },
    checkPassword() {
      if (this.newPassword1.length < 1 || this.newPassword2.length < 1) {
        this.passError = false;
        return;
      }
      if (this.newPassword1 !== this.newPassword2) {
        this.passError = true;
      } else {
        this.passError = false;
      }
    },

    loginAuth() {
      this.$store
        .dispatch("tokenAuth", this.$route.params.token)
        .then(() => {
          this.$store.dispatch("setAdditionalTranslations");
          this.$store.dispatch("checkAnsweredQuestionaire").then((res) => {
            if (this.$route.query.showModal == 1) {
              return;
            }

            if (res == "error") {
              console.log("error");
            } else {
              if (
                this.$store.getters.getCompletedQuestionnaire &&
                this.$store.getters.getAdditionalQuestionsAnswered == null
              ) {
                this.$router.push("/completion-message");
              } else if (
                this.$store.getters.getCompletedQuestionnaire &&
                this.$store.getters.getAdditionalQuestionsAnswered != null
              ) {
                this.$router.push("/membership-area/home");
              } else {
                if (!this.$store.getters.hideFirstMessage) {
                  this.$router.push("/welcome-message");
                }
                // else {
                //   this.$router.push("/personality-test");
                // }
              }
            }
          });
        })
        .catch((err) => console.log(err));
    },
  },
  beforeMount() {
       // this.loginAuth();

  },
  mounted(){

    console.log('siteId reset page', this.$store.getters.siteId)


  }
};
</script>
